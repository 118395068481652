import { createApp, computed } from 'vue';
import apiClient from '@/apiClient';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import { Skeletor } from 'vue-skeletor';
import store from '@/store';
import '@vuepic/vue-datepicker/dist/main.css';

import App from '@/App.vue';

/* Global style */
import '../css/styles.css';
import Auth from './services/Auth';

const app = createApp(App)
  .use(router)
  .component(Skeletor.name, Skeletor)
  .use(i18n);

const globals = app.config.globalProperties;
globals.apiClient = apiClient;
globals.authUser = computed(() => store.auth.user);
globals.hasPermission = Auth.hasPermission;
globals.hasProvoicePermission = Auth.hasProvoicePermission;
globals.provoiceRootCustomer = process.env.VUE_APP_PROVOICE_ROOT_CUSTOMER;
globals.isRootCustomerPortal = (
  (portal) => String(portal) === String(globals.provoiceRootCustomer)
);
globals.settings = {
  roles: [],
};

router.isReady().then(async () => {
  app.mount('#app');
});
